// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "node_modules/ngx-smart-modal/styles/ngx-smart-modal.css";

// Material icons
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(./assets/fonts/Material-Icons.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    // -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

//SummerNote Fix Start
.note-modal-backdrop {
    display: none !important;
}
.ngx-summernote-view {
    color: black;
}
.ngx-summernote-view img{
    display: inline-block;
    max-width: 100%;
    width: auto;
}
//SummerNote Fix END

.fixed-top-content {
    background: var(--ion-color-secondary);
    padding-left: 14px !important;
}

.table-header {
    background: var(--ion-color-default);
    color:black;
    padding: 5px;
    padding-left: 10px;
}

.table-body {
    background: var(--ion-color-light);
    padding: 5px;
    padding-left: 10px;
    border-bottom: 1px solid var(--ion-color-light-shade);
    min-height: 45px;
    label {
        cursor: pointer;
    }
}
.table-sub-item-body {
    background: var(--ion-color-light);
    padding: 5px;
    padding-left: 10px;
    border-bottom: 1px solid var(--ion-color-light-shade);
    min-height: 45px;
    label {
        cursor: pointer;
    }
}


.newItem{
    background: var(--ion-color-light-shade);
}
.table-body:hover {
    background: var(--ion-color-light-shade);
    cursor: pointer;
    .table-sub-item-body{
    background: var(--ion-color-light-shade);
    border-bottom-color:var(--ion-color-light);

}
}

.homePage ion-icon {
    font-size: 100px;
}

ion-card {
    cursor: pointer;
    outline: none;
    margin-left: 15px !important;
}

.invalid-feedback {
    color: #f44336;
    font-size: 12px;
    margin-top: 5px;
}

.content-body ion-icon {
    font-size: 25px;
}

.domainImage {
    display: block;
    max-height: 100px;
    margin: 0 auto;
}

.domainName {
    pointer-events: none;
    background: none;
}

.edit-input {
    background: white !important;
}

.native-input.sc-ion-input-md,
.native-textarea.sc-ion-textarea-md {
    padding-left: 8px;
}



ion-menu-toggle>ion-item {
    cursor: pointer;
}

ion-button {
    text-transform: inherit;
}

.card-content-md {
    font-size: 16px;
}

.alert-button-inner {
    text-transform: capitalize;
}

ion-segment-button {
    text-transform: inherit;
}

ion-segment-button {
    font-size: 16px;
}

ion-segment-button button {
    margin-top: 0 !important;
}

.report {
    height: calc(100vh - 111px);
    max-height: calc(100vh - 111px);
    overflow: hidden;
}

.search-containter {

    ion-item.item:hover a {
        background-color: white;
        }

        ion-item {
          //   display: flex;
          height: 37px;
          // align-items: start;
          --background: white;
          --background-hover: white;
          border-radius: 100px;
          --border-radius: 100px;
          box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px #00000033, 0 1px 5px 0 #0000001f;
          --color: var(--ion-color-step-850, #262626);
          --min-height: 37px;
          //border: 1px solid #cccc;
          margin: 0 4px;
        }

        ion-label {
          margin: 0;
        }

        ion-select {
          display: flex;
          text-align: center;
          min-height: 37px;
          // margin-top: -10px;
        }

        .ionic-selectable-icon-inner {
          position: absolute;
          top: 15px;
          left: 0px;
          right: 14px;
        }
        }

.search-containter.detail-page {
    padding: 5px 0;
}

.search-containter.no-button-list {
    padding: 4px 0;
}

.detail-container {
    padding-left: 5px;
}

ion-select {
    width: 100%;
    min-width: 100%;
    padding-left: 7px !important;
}

::placeholder,
 ::-webkit-input-placeholder {
    color: var(--color);
    opacity: 0.4;
}

ion-segment-button:hover {
    background: none !important;
}

.codeModal {
    text-align: center !important;
}
.codeModal img{
    display: inline-block !important;
}
.nsm-dialog{
    top: 50%;
    // transform: translateY(-50%);
}
.nsm-content{
width: 464px;
}

.downloadBtn{
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    padding: 9px 0;
    text-transform: capitalize;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0 2px 2px 0 rgba(0,0,0,0.14),0 1px 5px 0 rgba(0,0,0,0.12);
    display: inline-block;
    width: 100%;
    font-family: var(--ion-font-family,inherit);
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    vertical-align: -webkit-baseline-middle;
    pointer-events: auto;
    -webkit-font-kerning: none;
    font-kerning: none;
    text-align: center;
    margin-left: unset;
    margin-right: unset;
    -webkit-margin-start: 2px;
    margin-inline-start: 2px;
    -webkit-margin-end: 2px;
    margin-inline-end: 2px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 100px;
}
.downloadBtn:focus{
    background: var(--ion-color-primary-shade,#3171e0);
}
.dateTimeInput{
    border: none;
    height: 26px;
    width: 100%;
}


.dateTimeInput:focus{
    border: none;
    outline: none;
    box-shadow: none;
}
ion-card-content{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.input-required{
    border: 1px solid #f44336;
}

button[disabled]{
    cursor: default;
    opacity: .4;
    pointer-events: none;
}
.ngx-modal-selectLocale{
    opacity: 0;
}

.alert-button-group.sc-ion-alert-md{
  flex-flow: row-reverse;
  place-content: end;
}

.questionPage .table-body:hover input:disabled, .questionPage textarea:disabled{
    background: var(--ion-color-light-shade);
    cursor: pointer;
}

.questionPage .native-input.sc-ion-input-md, .questionPage .native-textarea.sc-ion-textarea-md{
    background: var(--ion-color-white);
}

.questionPage .native-input.sc-ion-input-md:disabled{
    background: var(--ion-color-shade);
    border:none;
    opacity: 1 !important;
}

.questionPage .native-textarea.sc-ion-textarea-md:disabled{
    background: var(--ion-color-shade);
    border:none;
}

 .questionPage .ion-native{
    --background: red;
    background: transparent;
    box-shadow: none;
    border: none;
}
// scrollbar to alert dialog
.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar, .alert-message.sc-ion-alert-md::-webkit-scrollbar, .alert-radio-group.sc-ion-alert-md::-webkit-scrollbar{
    width: 0.7em;
    display: block !important;
}
// .alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-track, .itemDefinitionDetailPage .alert-message.sc-ion-alert-md::-webkit-scrollbar-track, .alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-track{
//     //  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
// }

.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-thumb, .alert-message.sc-ion-alert-md::-webkit-scrollbar-thumb,  .alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-thumb{
   background-color: darkgrey;
    outline: 1px solid slategrey;
}

.d-none {
    display: none !important;
}

.domain-card-content {
    height: calc(100vh - 253px);
}

.domian-card-section {
    height: calc(100vh - 286px);
    overflow: auto;
    padding: 10px;
}


.twilio-modal-max .modal-wrapper{
    max-height: 490px !important;
    max-width: 400px !important;
    // min-height: 160px !important;
    // height: auto !important;
}
.twilio-modal-min .modal-wrapper{
    max-height: 310px !important;
    max-width: 350px !important;
    // min-height: 160px !important;
    // height: auto !important;
}

.color-picker button {
    background-color: var(--ion-color-secondary);
    color: white;
    height: 20px;
    margin-right: 5px;
}

.alert-radio-label {
    text-transform: initial !important;
}

.alert-checkbox-label{
  text-transform: none !important;

}

.detail-editor.ng-invalid.ng-touched > .angular-editor {
    border-bottom: 2px solid #f04141;
}

.d-flex {
    display: flex;
}

.custom-loading-spinner ~ .loading-wrapper{
    display: none !important;
}

.custom-loading {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    font-family: var(--ion-font-family, inherit);
    font-size: 14px;
    z-index: 99999999;
    .backdrop {
        background: #000;
        opacity: 0.32;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .loading-wrapper {
        background: white;
        padding: 24px;
        display: flex;
        align-items: inherit;
        justify-content: inherit;
        width: auto;
        z-index: 10;
        border-radius: 2px;
        color: #262626;
    }
    .loading-content {
        margin-left: 16px;
    }
}
.qr-code-canvas{
    width: 200px !important;
    height: 200px !important;
    text-align-last: center !important;
    display: block;
    margin: 0 auto;
}

.sc-ion-alert-md-h {
  --min-width: 250px;
  opacity: 0;
  animation: fadeIn 0s 0.1s forwards;
}



@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.sc-ion-alert-md-h.conditionally-styled{
  --min-width: 400px !important;
  opacity: 1;
}

//p-tree

p-treenode {
    display: block;
}

.ui-tree {
    width: 18em;
}
.ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    width: 90% !important;
    cursor: pointer;
}
.ui-tree .ui-treenode-selectable.ui-treenode-content {
    cursor: pointer;
}

.ui-tree .ui-tree-container {
    height: 100%;
    margin: 0;
    overflow: hidden !important;
    padding: .25em;
    white-space: nowrap;
}

.ui-tree-empty-message {
    padding: .25em;
    padding: 18px;
}

.ui-tree .ui-treenode-children {
    margin: 0;
    padding: 0 0 0 1em;
}

.ui-tree .ui-treenode {
    background-attachment: scroll;
    background-color: transparent;
    background-image: none;
    background-position: 0 0;
    background-repeat: repeat-y;
    list-style: none outside none;
    margin: 0;
    padding: .125em 0 0 0;
}

.ui-tree .ui-treenode-droppoint {
    height: 4px;
    list-style-type: none;
}

.ui-tree .ui-treenode-droppoint-active {
    border: 0 none;
}

.ui-tree .ui-tree-toggler {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

.ui-tree .ui-treenode-icon {
    display: inline-block;
    vertical-align: middle;
}

.ui-tree .ui-treenode-label {
    display: inline-block;
    padding: 0 .25em;
    vertical-align: middle;
}

.ui-tree .ui-treenode-label.ui-state-hover,
.ui-tree .ui-treenode-label.ui-state-highlight {
    font-weight: normal;
    border: 0 none;
}

.ui-tree .ui-treenode.ui-treenode-leaf>.ui-treenode-content>.ui-tree-toggler {
    visibility: hidden;
}

.ui-tree .ui-chkbox-box {
    cursor: pointer;
}

.ui-tree .ui-chkbox {
    display: inline-block;
    vertical-align: middle;
}

.ui-tree .ui-chkbox .ui-chkbox-icon {
    margin-left: 1px;
}

.ui-tree .ui-tree-filter {
    width: 100%;
    box-sizing: border-box;
    padding-right: 1.5em;
}

.ui-tree .ui-tree-filter-container {
    position: relative;
    margin: 0;
    padding: 0.4em;
    display: inline-block;
    width: 100%;
}

.ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
    position: absolute;
    top: .8em;
    right: 1em;
}

/** Fluid **/
.ui-fluid .ui-tree {
    width: 100%;
}

/** Horizontal Tree **/
.ui-tree-horizontal {
    width: auto;
    padding: .5em 0;
    overflow: auto;
}

.ui-tree.ui-tree-horizontal table,
.ui-tree.ui-tree-horizontal tr,
.ui-tree.ui-tree-horizontal td {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}

.ui-tree.ui-tree-horizontal .ui-tree-toggler {
    vertical-align: middle;
    margin: 0;
}

.ui-tree-horizontal .ui-treenode-content {
    font-weight: normal;
    padding: 0.4em 1em 0.4em 0.2em;
}

.ui-tree.ui-tree-horizontal .ui-tree-node-label {
    margin: 0;
}

.ui-tree-horizontal .ui-treenode-parent .ui-treenode-content {
    font-weight: normal;
    white-space: nowrap;
}

.ui-tree.ui-tree-horizontal .ui-treenode {
    padding: .25em 2.5em;
}

.ui-tree.ui-tree-horizontal .ui-treenode.ui-treenode-leaf,
.ui-tree.ui-tree-horizontal .ui-treenode.ui-treenode-collapsed {
    padding-right: 0;
}

.ui-tree.ui-tree-horizontal .ui-treenode-children {
    padding: 0;
    margin: 0;
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector {
    width: 1px;
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector-table {
    height: 100%;
    width: 1px;
}

.ui-tree.ui-tree-horizontal .ui-treenode-connector-line {
    width: 1px;
}

.ui-tree.ui-tree-horizontal table {
    height: 0;
}

.ui-tree.ui-tree-horizontal .ui-chkbox {
    vertical-align: bottom;
    margin-right: .25em;
}

/** Loading **/
.ui-tree.ui-tree-loading {
    position: relative;
    min-height: 4em;
}

.ui-tree .ui-tree-loading-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    opacity: 0.1;
    z-index: 1;
}

.ui-tree .ui-tree-loading-content {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    margin-top: -1em;
    margin-left: -1em;
}

.ui-tree .ui-tree-loading-content .ui-tree-loading-icon {
    font-size: 2em;
}

body .ui-tree {
    border: none !important;
    // background-color: #eeeeee;
    color: #333333;
    padding: 0 !important;
    width: 100% !important;
    margin-left: -18px;
}

body .ui-tree .ui-tree-container {
    padding: 0;
    margin: 0;
}

body .ui-tree .ui-tree-container .ui-treenode {
    padding: 0.143em 0;
    padding-left: 0.15em !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
    padding: 0;
    border: 1px solid transparent;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content:focus {
    outline: none !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
    vertical-align: middle;
    display: inline-block;
    float: none;
    margin: 0 0.143em 0 0;
    color: #848484;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
    vertical-align: middle;
    display: inline-block;
    margin: 0 0.143em 0 0;
    color: #848484;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    margin: 0;
    vertical-align: middle;
    display: inline-block;
    padding: 0.286em;
}
.ngx-modal-activityListFoldersFilter .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-objectTypeFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-folderEntryFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-addNewFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-objectTemplateFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-approveUploadsFoldersFilter .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-userActivityFoldersFilter .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-FoldersFilter .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label,
.ngx-modal-objectTemplateFoldersTree .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
    width: 77% !important;
    cursor: pointer;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
    background-color: #007ad9;
    color: #ffffff;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
    margin: 0 0.5em 0 0;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable.ui-treenode-content-selected{
    // outline: solid lightgray !important;
    font-weight: bold !important;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
    margin: 1px 0 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label.ui-state-highlight {
    cursor: default;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
    background-color: #eaeaea;
    color: #333333;
    cursor: pointer;
}

// body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label:hover {
//     background-color:lightgray !important;
// }

.no-ripple{
    --background-hover: transparent !important
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content>span {
    line-height: inherit;
}

body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-dragover {
    background-color: #eaeaea;
    color: #333333;
}

body .ui-tree .ui-tree-container .ui-treenode-droppoint.ui-treenode-droppoint-active {
    background-color: #007ad9;
}

body .ui-tree.ui-tree-horizontal {
    padding-left: 0;
    padding-right: 0;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content {
    border: 1px solid #c8c8c8;
    background-color: #ffffff;
    color: #333333;
    padding: 0.571em 1em;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-state-highlight {
    background-color: #007ad9;
    color: #ffffff;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon {
    color: #007ad9;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-treenode-label:not(.ui-state-highlight):hover {
    background-color: inherit;
    color: inherit;
}

body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
    background-color: #eaeaea;
    color: #333333;
}

body .ui-tree .ui-tree-filter-container .ui-tree-filter {
    width: 100%;
    padding-right: 2em;
}

body .ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
    top: 50%;
    left: auto;
    margin-top: -0.5em;
    right: 0.8em;
    color: #007ad9;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content>span{
    color: rgb(0, 0, 0);
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label {
    background-color: transparent;
    color: rgb(0, 0, 0);
}
.ui-treenode-content{
    display: inline-block !important;
}

body .ui-contextmenu {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    width: 250px;
  }
  body .ui-contextmenu .ui-menuitem-link {
    padding: 0.714em 0.857em;
    color: #333333;
    font-weight: normal;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-transition: box-shadow 0.2s;
    -o-transition: box-shadow 0.2s;
    -webkit-transition: box-shadow 0.2s;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  body .ui-contextmenu .ui-menuitem-link .ui-menuitem-text {
    color: #333333;
  }
  body .ui-contextmenu .ui-menuitem-link .ui-menuitem-icon {
    color: #333333;
    margin-right: 0.5em;
  }
  body .ui-contextmenu .ui-menuitem-link:not(.ui-state-disabled):hover {
    background-color: #eaeaea;
  }
  body .ui-contextmenu .ui-menuitem-link:not(.ui-state-disabled):hover .ui-menuitem-text {
    color: #333333;
  }
  body .ui-contextmenu .ui-menuitem-link:not(.ui-state-disabled):hover .ui-menuitem-icon {
    color: #333333;
  }
  body .ui-contextmenu .ui-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: inset 0 0 0 0.2em #8dcdff;
    -moz-box-shadow: inset 0 0 0 0.2em #8dcdff;
    box-shadow: inset 0 0 0 0.2em #8dcdff;
  }
  body .ui-contextmenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
    color: #007ad9;
  }
  body .ui-contextmenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
    color: #007ad9;
  }
  body .ui-contextmenu .ui-submenu-list {
    padding: 0;
    background-color: #ffffff;
    border: 1px solid #c8c8c8;
    -webkit-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.16);
  }
  body .ui-contextmenu .ui-menuitem {
    margin: 0;
  }
  body .ui-contextmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
    right: 0.429em;
  }
  body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
    background-color: #eaeaea;
  }
  body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
    color: #333333;
  }
  body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
    color: #333333;
    font-size: 21px;
    margin-bottom: -3px;
  }
  body .ui-contextmenu .ui-menu-separator {
    border: 1px solid #c8c8c8;
    border-width: 1px 0 0 0;
  }

@media only screen and (max-width: 992px) {
    ion-segment{
        margin-bottom: 10px;
    }
    .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
        width: 77% !important;
        cursor: pointer;
    }

}

//p-tree
.input-group.is-invalid {
    //border: solid 2px #f36d6d !important;
    border-bottom: solid 2px red !important;
}

.ion-touched-me.ion-untouched {
    --highlight-background: red;
    --full-highlight-height: 2px;
}
// .wysiwyg-editor.ng-invalid .note-editor {
//     border-bottom: 2px solid red !important;
// }

.select-modal {
    .searchbar-input {
        box-shadow: none;
    }
}

.ionic-selectable-icon {
     position: absolute !important;
     right: 0 !important;
     top: 0 !important;
     margin-right: 5px !important;
}
.ionic-selectable {
    display: block !important;
    min-width: 95%;
    max-width: 95% !important;
    height: 36px;
    .ionic-selectable-value {
        padding-top: 9px;
        padding-bottom: 9px;
    }
}

ion-label + ionic-selectable {
    min-width: 0 !important;
}

.disable-row {
    pointer-events: none;
    opacity: 0.7;
    ion-button {
        pointer-events: none;
    }
}

.flag-icon {
    position: absolute;
    left: 62px;
    bottom: 17px;
}


.customLocaleSelect .alert-radio-label, .customLocaleSelect .alert-checkbox-label {
    padding-left: 73px !important;
}

.customLocaleSelect .alert-radio-label, .customLocaleSelect .alert-checkbox-label {
    padding-inline-start: 72px;
    padding-left: 73px !important;
}
// .flag-icon-checkbox{
//     left: 48px !important;
// }
.headerButton {
    --background: var(--ion-color-default) !important;
     --background-hover: var(--ion-color-default) !important;
        color: black !important;
        --color-focused: black !important;
        --color-activated: black !important;
     --background-activated: var(--ion-color-default) !important;
     --background-focused: var(--ion-color-default) !important;

  }

  .color-picker button{
    background: var(--ion-color-default) !important;
    color: black !important;
}
// .multi-select-modal{
//     max-width: 350px !important;
//     max-height: 350px !important;
//     top: 25%;
//     left: 30%;
//     z-index: 1;
//     justify-content: normal;
//     contain: none;
//     --height: 350px;
//     --min-height: 350px;
//     --max-height: 350px;

//     ion-backdrop.sc-ion-modal-md.md.hydrated {
//         opacity: 0.3;
//         height: 100%;
//         position: fixed;
//         touch-action: none;
//     }
// }
// .select-input-item, .select-input-item :hover, .select-input-item :focused {
//     cursor: pointer !important;
//     z-index: 99;
// }

.select-input input[disabled], .client-select input[disabled] {
    cursor: pointer !important;
    opacity: 1 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.select-input {
    .native-input{
        text-overflow: ellipsis !important;
        overscroll-behavior: none !important;
        display: block;
    &:hover{
        cursor: pointer !important;
    }
}
}

.multi-select-modal .modal-wrapper{
    max-height: 355px !important;
    max-width: 325px !important;
     --height: 350px;
    --min-height: 350px;
}

.multi-select-modal {
    border-radius: 4px !important;
}
.select-icon{
    font-size: 20px !important;
    opacity: 0.6 !important;
     &:hover{
        cursor: pointer !important;
    }
}

.ion-color-red {
    --ion-color-base: var(--ion-color-danger) !important;
}

.ion-color-black {
    --ion-color-base: var(--ion-color-dark) !important;
}

ion-item:not(.item-has-value):not(.item-has-focus)>.label-floating {
    transform: translate3d(0,150%,0) !important;
}
ion-menu {
    --ion-background-color: #f0eeee;
    border: none;
    .header-md::after {
        width: 0;
        height: 0;
    }
    ion-toolbar {
    --background: #f0eeee;
    }
    ion-content{
        --background: #f0eeee;
        ion-item{
            --background: #f0eeee;
            &:hover{
                --background: #d7d2d2;
                background: #d7d2d2 !important;
            }
        }
    }
    ion-list {
    background: transparent;
    }

}
.searchbar {
  height: 36px;
  display: flex;
    --background: white;
    border-radius: 100px;
    --border-radius: 100px;
    box-shadow: 0 2px 2px 0 #00000024, 0 3px 1px -2px #00000033, 0 1px 5px 0 #0000001f;
    --color: var(--ion-color-step-850, #262626);
    --min-height: 36px;
    width: 350px;
    .search-icon {
        margin: 0;
        margin-right: 10px;
        cursor: pointer;
        padding-left: 0;
    }
    .close-icon, .mic-icon {
        margin: 0;
        margin-left: 10px;
        cursor: pointer;
        margin-right: -21px
    }
    ion-input {
        --padding-top: 0px;
        --padding-bottom: 0px;
    }
}

.right-action-container {
    ion-button, .header-select {
        width: 200px;
        height: 36px;
        display: flex;
        flex-flow: column;
    }
}

.header-checkbox {
    --min-height: 36px;
    ion-checkbox {
        margin: 0;
    }
}

.adjustableColumnContainer {
    display:flex;
    justify-content:flex-start;
    flex-direction: row;
  }

.adjustableColumn {
    position: relative;
     width:40vw;
    flex-grow:0;
}

.divider {
    height: 1px;
    box-shadow: 0px 1px 1px 0px #00000066;
    display: flex;
    flex-direction: row;
    margin: 15px 0;
}
  .adjustableColumn2{
    position: relative;
    width:95vw;
   flex-grow:0;
  }

  .toast{
    max-width: 350px;
    left: 50%;
    text-align: center !important;
    transform: translateX(-50%);
    --background: var(--ion-color-light) !important;
    color: black;
}
.customSelectInterface {
    .alert-wrapper{
        max-height: 450px;
        min-width: 30em !important;
        .alert-radio-group{
            max-height: 350px !important;
        }
    }
}

.description-control{
    width: 350px;
}
.answer-info-list-icon{
    // padding-inline:0.8rem !important;
    font-size: 21px !important;
    position: absolute;
    left: 55px;
    bottom: 9px;
  }

.customAnswerSelect .alert-radio-label, .customAnswerSelect .alert-checkbox-label {
    padding-left: 65px !important;
}



  .alertConfirmation{
  .alert-button-group.sc-ion-alert-md {
      flex-direction: row;
  }
}
.sort-header {
    ion-col {
        cursor: pointer;
        display: flex;
        align-items: center;
        ion-label {
            cursor: pointer;
        }
        ion-icon {
            font-size: 16px !important;
        }
    }
}

.ngx-modal-confirmPassword.nsm-dialog{
    top: 50%;
    transform: translateY(-50%);
}

.ngx-modal-Filter.nsm-dialog{
  width: auto !important;
  visibility: hidden !important;
  top: 10%;
  transform: translate(-50%, 0%);
  position: absolute;
  left: 50%;
}

.input-group {
    background-color: #fff;
    border-radius: 25px;
    border: 0;
    // float: left;
    height: 38px;
    // margin-bottom: 15px;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
}
.form-control-passwordPrompt{
    background-color: #eee;
    border-radius: 25px;
    border: 0;
    font-size: 16px;
    height: 38px;
    padding: 0 20px;
    width: 100%;
    color: #000;

.native-input.sc-ion-input-md{
    padding-left: 20px;
}

}

ion-select::part(container){
  flex: auto !important;
}



ion-item::part(native){
height: 37px;
}

.alert-radio-label.sc-ion-alert-md{
  margin-right: -33px;
}

.pie-label {
  font-size: 16px !important;
}

.alert-wrapper.sc-ion-alert-md{
  --min-width: 450px;
  --max-width: 280px;
  max-height: 381px;
}

.owlDateTime{
  display: flex;
  align-items: center;
  flex: auto;
  padding-top: 4px;
}

.calender-icon{
  color: #757575;
  font-size: 25px;
}

.sc-ion-input-md-h:not(.legacy-input) {
  min-height: 36px!important;
}

ion-select::part(placeholder){
  opacity: 45%;
  }

  .label-text-wrapper sc-ion-input-md{
    display: none!important;
  }


  // .input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md {
  //   margin-left: -8px!important;;
  // }

  // .input-label-placement-floating.sc-ion-input-md-h input.sc-ion-input-md {
  //   margin-left: -8px!important;;

  // }
